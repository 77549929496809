html,
body {
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: none;
  font-size: 18px;
  height: 100%;
  line-height: 1.5;
  color: rgba(29, 38, 47, 1);
  background: #fff;
  font-family: "dm sans";
  scroll-behavior: smooth;
}

ul {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0;
}
